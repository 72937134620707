import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  Text,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  Center,
  useToast,
} from '@chakra-ui/react';

import useMutation from '@/hooks/useMutation';
import useInvitation from '@hooks/useInvitation';
import useFormFields from '@/hooks/useFormFields';
import getTimeNow from '@invitato/helpers/dist/getTimeNow';

import { BUTTON_PROPS } from '@/constants/colors';
import { BG_CONFIRMATION } from '@/constants/assets';
import { API_HOSTNAME, SHEET_GIFT_CONFIRMATION } from '@/constants';
import { ENABLE_SEND_GIFT, ENABLE_BANK_TRANSFER } from '@/constants/feature-flags';

import txtWording from './locales';

const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  colorScheme: 'blackAlpha',
  borderRadius: '0',
  backgroundColor: 'bgSecondary',
  color: 'mainColorText',
  border: 'none',
  _placeholder: { color: 'mainColorText' },
  _focus: { borderColor: 'mainColorText' },
};

const FORM_LABEL_PROPS = {
  fontSize: 'sm',
  color: 'mainColorText',
  margin: '8px 0 0 0',
};

function Confirmation({ lang, onBack }) {
  const [loading, setLoading] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const toast = useToast();
  const invitation = useInvitation();

  const [onConfirm] = useMutation(
    API_HOSTNAME,
    SHEET_GIFT_CONFIRMATION,
    'insert_gift_confirmation',
  );
  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: '',
    isGift: false,
    note: '',
    hp: '',
  });

  const onSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    // set loading state
    setLoading(true);

    const getYesNo = (p) => (p ? 'YA' : 'TIDAK');
    const query =
      `nama=${formFields.name}` +
      `&hp=${formFields.hp}` +
      `&catatan=${formFields.note}` +
      `&bank_transfer=${getYesNo(isBankTransfer)}` +
      `&hadiah_fisik=${getYesNo(isGiftCard)}` +
      `&waktu=${getTimeNow()}`;

    const result = await onConfirm(query);

    if (result.success) {
      toast({
        title: txtWording.submitted[lang],
        description: txtWording.successSent[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onResetForm();
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedSent[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  return (
    <Box
      minHeight="100vh"
      bgPosition="center"
      padding="32px"
      bgColor="bgPrimary"
      bgSize="cover"
      bgImage={`url(${BG_CONFIRMATION})`}
    >
      <Box
        bgColor="bgPrimary"
        border="6px solid"
        borderColor="bgSecondary"
        padding="24px 16px"
        borderRadius="24px"
      >
        {/* Heading & Desc Section */}
        <Box textAlign="center" color="mainColorText">
          <Heading fontWeight="normal" fontSize="4xl" fontFamily="cursive">
            {txtWording.title[lang]}
          </Heading>
          <Text fontSize="sm" padding="16px 0 ">
            {txtWording.desc[lang]}
          </Text>
        </Box>
        <Box>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.hp[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              value={formFields.hp}
              onChange={createChangeHandler('hp')}
            />
          </FormControl>
          <FormControl margin="24px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.giftType[lang]}:</FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  size="sm"
                  borderColor="secondaryColorText"
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                  color="mainColorText"
                >
                  {txtWording.bank[lang]}
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox
                  value={isGiftCard}
                  borderColor="secondaryColorText"
                  size="sm"
                  onChange={() => setIsGiftCard(!isGiftCard)}
                  color="mainColorText"
                >
                  {txtWording.gift[lang]}
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.notes[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              placeholder="..."
              onChange={createChangeHandler('note')}
            />
          </FormControl>
        </Box>
        <Box marginTop="24px">
          <Center>
            <Button
              isLoading={loading}
              fontWeight="normal"
              size="sm"
              color="alternativeDark"
              bgColor="bgSecondary"
              textTransform="uppercase"
              letterSpacing="2px"
              fontFamily="body"
              onClick={onSendConfirmation}
              {...BUTTON_PROPS}
            >
              {txtWording.sendConfirm[lang]}
            </Button>
          </Center>
        </Box>
      </Box>
      <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            fontWeight="normal"
            color="white"
            colorScheme="blackAlpha"
            letterSpacing="2px"
            onClick={onBack}
          >
            {invitation
              ? txtWording.backToHome[lang]
              : txtWording.backToAnnoncement[lang]
            }
          </Button>
        </Center>
      </Box>
      <Box height="50px" />
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: 'id',
};

export default Confirmation;